import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import logo from "../assets/images/logo/newLogo.svg";
import { createAbonnement } from "../services/abonnement";
import "../assets/css/LoadingBar.scss";
import routes from "../router/routes";
import {
  sendEmailErrorCreateAbonnement,
  sendEmailSuccessCreateAbonnement,
} from "../services/email";

const SuccesCheckout = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const commandeID = searchParams.get("commande");
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        const newProgress = prevProgress >= 100 ? 100 : prevProgress + 0.5;
        if (newProgress >= 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 50);
    async function createAbon() {
      createAbonnement({ commande: commandeID })
        .then(async (response) => {
          console.log(response);
          await sendEmailSuccessCreateAbonnement(response);
          navigate(routes.success);
        })
        .catch(async (error) => {
          const informationCompanyResponse = window.localStorage.getItem(
            "informationCompanyResponse"
          );
          const commande = JSON.parse(JSON.parse(informationCompanyResponse));
          await sendEmailErrorCreateAbonnement(commande, JSON.stringify(error));
          navigate(routes.error);
        });
    }
    createAbon();

    return () => clearInterval(interval);
  }, [commandeID, navigate]);

  const message = () => {
    switch (true) {
      case loadingProgress <= 20:
        return "Cablage du numéro";
      case loadingProgress <= 40:
        return "Création du compte client";
      case loadingProgress <= 70:
        return "Création des messages vocaux";
      case loadingProgress <= 100:
        return "Réglages des horaires d’ouvertures et numéros de renvois";
      default:
        return "Finalisation de la configuration du standard ";
    }
  };

  return (
    <div className="SuccessCheckout">
      <img src={logo} alt="logo" />
      <div className="SuccessCheckInfos">
        <div class="loading-bar">
          <div
            class="progress-bar"
            style={{ width: loadingProgress + "%" }}
          ></div>
        </div>
        <div class="status">
          <p class="state">{message()}</p>
          <p className="percentage">{loadingProgress.toFixed(0)} %</p>
        </div>
      </div>
    </div>
  );
};

export default SuccesCheckout;
