import Steps from "./Steps";
import OfferDetailsTry from "./OfferDetailsTry";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../stripe/CheckoutForm";
import { InfoIcon } from "../../assets/svgs";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import {
  stripeCreatePaymentIntent,
} from "../../services/stripe";

const stripePubKey =
  process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_STRIPE_PUB_KEY_LIVE
    : process.env.REACT_APP_STRIPE_PUB_KEY_TEST;
const stripePromise = loadStripe(stripePubKey);

const Payment = () => {
  const informationCompanyResponse = window.localStorage.getItem(
    "informationCompanyResponse"
  );
  const commande = JSON.parse(JSON.parse(informationCompanyResponse));
  const [clientSecret, setClientSecret] = useState(null);
  useEffect(() => {
    stripeCreatePaymentIntent({ commande: commande.id }).then((res) => {
      setClientSecret(res.intent.client_secret);
    });
  }, [commande.id]);
  return (
    <div className="px-4 lg:px-8 w-full lg:w-8">
      <Steps step={3} maxStep={3} />
      <OfferDetailsTry commande={commande} />
      <Tooltip target={"#why-card"} />
      <div className="w-full  flex align-items-center my-4">
        <span className="font-medium text-lg">
          Pourquoi dois-je saisir ma carte bancaire pour un essai gratuit ?{" "}
        </span>
        <span className="mx-4 flex align-items-center">
          <InfoIcon
            id={"why-card"}
            tooltip={`Une empreinte bancaire d’un montant de (${Number(Number(commande.prix) * 1.2).toFixed(2)} TTC) sera réalisée. Le montant sera débité uniquement si vous validez la période d’essai. Si vous annulez votre essai, l’empreinte bancaire sera annulée`}
          />
        </span>
      </div>
      {clientSecret && (
        <div className="w-full md:w-10">
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm commande={commande.id} clientSecret={clientSecret} />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default Payment;
