const errorMessages = {
  required: "Ce champ est requis",
  email: "Email invalide",
  codePostal: "Code postal invalide",
  unSelectedRadio: "Veuillez sélectionner une option",
  invalidPhoneNumber: 'Numéro français invalide',
  invalidPhoneNumberFirtChiffre: 'Numéro doit commencer par 0',
  invalidPhoneNumberSecondChiffre: "Le deuxième caractère doit être un chiffre de '1' à '9'.",
};

export default errorMessages;
