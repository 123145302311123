import sendRequest from "../API";
import API_Routes from "../API/API_Routes";

const createCheckoutSessionEssai = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.createCheckoutSessionEssai,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const createStripeCustomer = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.createStripeCustomer,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const createStripeSubscription = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.createStripeSubscription,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const cancelStripeSubscription = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.cancelStripeSubscription,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const stripeAttachPaymentMethod = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.stripeAttachPaymentMethod,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const stripeConfirmSetupIntent = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.stripeConfirmSetupIntent,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const stripeCreateSetupIntent = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.stripeCreateSetupIntent,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const stripeConfirmPaymentIntent = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.stripeConfirmPaymentIntent,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const stripeCreatePaymentIntent = async (data, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.stripeCreatePaymentIntent,
      "POST",
      data,
      signal
    );
    return response;
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

const handle3DSecureSI = async (setupIntentId, stripe) => {
  try {
    // Confirm the Setup Intent with Stripe.js
    const { error } = await stripe.confirmCardSetup(setupIntentId);

    if (error) {
      return {
        success: false,
        message: error.message,
      };
    } else {
      // Handle success
      console.log("Setup Intent confirmed successfully");
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error.message,
    };
  }
};

const handle3DSecurePI = async (setupIntentId, stripe) => {
  try {
    // Confirm the Setup Intent with Stripe.js
    const { error } = await stripe.confirmCardPayment(setupIntentId);

    if (error) {
      return {
        success: false,
        message: error.message,
      };
    } else {
      // Handle success
      console.log("Setup Intent confirmed successfully");
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: error.message,
    };
  }
};

const intentHandler = async (intent, stripe, confirmFn) => {
  try {
    if (intent.status === "requires_confirmation") {
      const confirmPaymentIntentResponse = await confirmFn({
        payment_intent: intent.id,
      });

      if (confirmPaymentIntentResponse.status === "requires_action") {
        if (confirmPaymentIntentResponse.object === "payment_intent")
          await handle3DSecurePI(
            confirmPaymentIntentResponse.client_secret,
            stripe
          );
        else if (intent.object === "setup_intent")
          await handle3DSecureSI(
            confirmPaymentIntentResponse.client_secret,
            stripe
          );
      }
    }
    if (intent.status === "requires_action") {
      if (intent.object === "payment_intent")
        await handle3DSecurePI(intent.client_secret, stripe);
      else if (intent.object === "setup_intent")
        await handle3DSecureSI(intent.client_secret, stripe);
    }

    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
};

export {
  createCheckoutSessionEssai,
  createStripeCustomer,
  createStripeSubscription,
  cancelStripeSubscription,
  stripeAttachPaymentMethod,
  stripeConfirmPaymentIntent,
  stripeCreatePaymentIntent,
  intentHandler,
  stripeCreateSetupIntent,
  stripeConfirmSetupIntent,
};
