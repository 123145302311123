import { createBrowserRouter } from "react-router-dom";
import Inscription from "../pages/Inscription";
import Error from "../pages/Error";
import NumberChoose from "../composants/inscription/NumberChoose";
import InformationCompany from "../composants/inscription/InformationCompany";
import Payment from "../composants/inscription/Payment";
import routes from "./routes";
import Success from "../pages/Success";
import SuccesCheckout from "../pages/SuccesCheckout";
import Maintain from "../pages/Maintain";
import Cancel from "../pages/Cancel";
import Error404 from "../pages/Error404";
import CancelCommande from "../pages/CancelCommande";
import Infos from "../pages/Config/Infos";
import Renvoi from "../pages/Config/Renvoi";
import Mandat from "../pages/Config/Mandat";
import Menu from "../pages/Config/Menu";
import ConfigChoix from "../pages/Config/Choix";
import ConfigFinish from "../pages/Config/Finish";

const router = createBrowserRouter([
  {
    path: routes.inscription,
    element: <Inscription />,
    children: [
      {
        path: routes.choixNumero,
        element: <NumberChoose />,
      },
      {
        path: routes.informationEntreprise,
        element: <InformationCompany />,
      },
      {
        path: routes.inscriptionPayment,
        element: <Payment />,
      },
    ],
  },
  { path: routes.successCheckout, element: <SuccesCheckout /> },
  { path: routes.success, element: <Success /> },
  { path: routes.error, element: <Error /> },
  { path: routes.cancel, element: <Cancel /> },
  { path: routes.cancelCommande, element: <CancelCommande /> },
  { path: routes.oops, element: <Maintain isError={true} /> },
  { path: routes.configInfos, element: <Infos /> },
  { path: routes.configRenvoi, element: <Renvoi /> },
  { path: routes.configMandat, element: <Mandat /> },
  { path: routes.configMenu, element: <Menu /> },
  { path: routes.configChoix, element: <ConfigChoix /> },
  { path: routes.configFinish, element: <ConfigFinish /> },
  { path: routes[404], element: <Error404 /> },
]);

export default router;
