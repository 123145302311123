import React, { useState } from "react";
import { CheckIcon } from "../assets/svgs";

const SidePanel = ({
  title,
  image,
  visibility,
  content = [],
  subtitle = null,
}) => {
  if (visibility === false) return null;
  return (
    <>
      <img src={image} alt="" className="aside-image" />
      <div className="aside-content">
        <h1 className="title font-normal">{title}</h1>
        <ul className="description">
          {content.map((item, index) => (
            <li key={index}>
              <CheckIcon />
              {item}
            </li>
          ))}
          {!!subtitle && <p>{subtitle}</p>}
        </ul>
      </div>
    </>
  );
};

export default SidePanel;
