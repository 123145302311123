import sendRequest from "../API";
import API_Routes from "../API/API_Routes";

const sendEmail = async (data) => {
  try {
    const response = await sendRequest(API_Routes.email, "Post", data);
    return response;
  } catch (err) {
    throw err;
  }
};

const sendEmailSuccessCreateAbonnement = async (data) => {
  const { commande } = data;
  await sendEmail({
    emailType: 6,
    commande: commande.id,
    domaine: process.env.REACT_APP_DOMAINE_ORIGIN,
  });
};

const sendEmailErrorCreateAbonnement = async (data, err) => {
  const { client, forfait } = data;
  await sendEmail({
    emailType: 3,
    provenance: "Tunnel Commande",
    error: {
      route: "Create Abonnement",
      error: err,
      data: {
        total: data.prix,
        forfait,
        data,
        client,
      },
    },
  });
};

export {
  sendEmail,
  sendEmailSuccessCreateAbonnement,
  sendEmailErrorCreateAbonnement,
};
