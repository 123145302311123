import React, { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import { useQuery } from "@tanstack/react-query";
import { fetchNumbersByZone } from "../../services/numbers";
import france from "../../assets/images/flag/france.png";
import { getNumbersByType, shuffleNumbers } from "../../utils/numbers";
import { RefreshIcon } from "../../assets/svgs";
import { useFormContext } from "react-hook-form";

const Numbers = ({ numberZone, numberType = null, selectedNumber }) => {
  const maxNumbers = window.innerWidth < 768 ? 3 : 6;
  const [numberView, setNumberView] = useState([]);
  const { data: numbers, isLoading } = useQuery({
    queryKey: ["numbers", numberZone],
    queryFn: async () => {
      const numbers = await fetchNumbersByZone(numberZone);
      if (!numberType) return numbers;
      const validNumbers = getNumbersByType(numbers, numberType);
      setNumberView(shuffleNumbers(validNumbers));
      return numbers;
    },
  });

  useEffect(() => {
    if (numbers && numberType) {
      const validNumbers = getNumbersByType(numbers, numberType);
      setNumberView(shuffleNumbers(validNumbers));
    }
  }, [numberType, numbers]);

  const { setValue, getValues } = useFormContext();
  if (!numberType) return null;
  if (isLoading)
    return (
      <Skeleton className="w-12 mt-2 pt-2 w-full" height="20vh"></Skeleton>
    );
  const shuffle = () => {
    const validNumbers = getNumbersByType(numbers, numberType);
    setNumberView(shuffleNumbers(validNumbers));
  };
  return (
    <div className="flex flex-wrap mt-2 pt-4 px-5 border-round-lg gap-4 light-primary-bg">
      {numberView.length > 0 ? (
        numberView.slice(0, maxNumbers).map((item, index) => (
          <div
            key={`${item.number}-${index}`}
            className="flex-1-40 flex-grow min-w-max cursor-pointer"
            onClick={() => {
              setValue("number", item.number);
              if (["argent", "or"].includes(getValues("numeroType"))) {
                const isDoublette =
                  [...item.number].reduce(
                    (count, char) => (char === " " ? count + 1 : count),
                    0
                  ) === 4;
                isDoublette
                  ? setValue("numType", `${getValues("numeroType")}_doublette`)
                  : setValue("numType", `${getValues("numeroType")}_triplette`);
              } else setValue("numType", `${getValues("numeroType")}`);
              console.log(getValues("numType"));
            }}
          >
            <div
              className={`border-round-lg p-3 flex align-items-center ${
                selectedNumber !== item.number
                  ? "bg-white"
                  : "primary-bg text-white"
              }`}
            >
              <input
                id={item.number}
                type="radio"
                className="ml-0 cursor-pointer"
                name="Number"
                value={item.number}
                checked={selectedNumber === item.number}
                onChange={() => {
                  setValue("number", item.number);
                  console.log(getValues("numberType"));
                }}
              />
              <label
                htmlFor={item.number}
                className=" ml-3 flex align-items-center cursor-pointer"
              >
                <img
                  width={24}
                  height={24}
                  className="france-flag"
                  src={france}
                  alt="France flag"
                  style={{ marginRight: "7px", marginLeft: "7px" }}
                />

                {item.number}
              </label>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center w-full font-bold text-red-500 pb-4">
          Numéros indisponible
        </p>
      )}
      {numberView.length > 0 && (
        <span
          className="col-12 flex align-items-center justify-content-center text-center gap-3 cursor-pointer select-none	"
          onClick={shuffle}
        >
          <RefreshIcon /> Générer d'autres numéros
        </span>
      )}
    </div>
  );
};

export default Numbers;
